const generateActions = (prefix) => {
	return {
		SENDING: prefix + 'SENDING',
		SUCCESS: prefix + 'SUCCESS',
		FAILURE: prefix + 'FAILURE',
		CLEAR: prefix + 'CLEAR',
	}
}

export default generateActions;
